.personnelHolography-detail[data-v-02ada668] {
  width: 100%;
  height: 100%;
}
.personnelHolography-detail[data-v-02ada668] .el-tabs__header.is-left {
  min-width: 130px;
  margin-right: 0;
}
.personnelHolography-detail[data-v-02ada668] .el-tabs--border-card {
  background-color: #f5f7fa;
}
.personnelHolography-detail[data-v-02ada668] .el-tabs__content {
  display: none;
}
.personnelHolography-detail .formContentBox[data-v-02ada668] {
  padding: 12px 0;
  height: calc(100% - 76px);
}